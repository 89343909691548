import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "category-table", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { icon: "mdi-chart-bar", inline: "", color: "secondary" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("stock_history_single")))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "secondary",
                rounded: "",
                absolute: "",
                fab: "",
                top: "",
                right: "",
              },
              on: {
                click: function () {
                  return _vm.handleBack()
                },
              },
            },
            [
              _c(VIcon, { attrs: { large: "" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
            ],
            1
          ),
          _vm.loading
            ? _c(VProgressCircular, {
                staticStyle: { "margin-left": "50%" },
                attrs: { indeterminate: "", size: "70", color: "primary" },
              })
            : _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("description")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$tc("stock", 1)))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("date")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("stocks")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.stockTypeHistoryData, function (stock, i) {
                    return _c("tr", { key: i }, [
                      _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(stock.stockTypeName) + " "),
                          stock.useValue
                            ? _c(
                                VChip,
                                {
                                  staticClass: "ma-2",
                                  attrs: { small: "", color: "primary" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("use_stock_label")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(" " + _vm._s(stock.stockTypeDescription) + " "),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatCurrency(
                                stock.currency,
                                stock.value,
                                2,
                                8
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(_vm.formatDate(stock.date)) + " "),
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 secondary",
                              attrs: { "min-width": "0", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openStockDialog(stock)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-pencil"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 error",
                              attrs: {
                                color: "red",
                                "min-width": "0",
                                small: "",
                              },
                              on: {
                                click: function () {
                                  return _vm.deleteStock(stock)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-trash-can"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
        ],
        1
      ),
      _vm.openDialogEditStock
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.openDialogEditStock,
                callback: function ($$v) {
                  _vm.openDialogEditStock = $$v
                },
                expression: "openDialogEditStock",
              },
            },
            [
              _c("AddStockToVehicleModal", {
                attrs: {
                  vehicleInfo: _vm.vehicleInfo,
                  stockEdit: _vm.stockEdit,
                  editByHistoryTable: true,
                },
                on: {
                  close: function ($event) {
                    _vm.openDialogEditStock = false
                  },
                  reloadStocks: function () {
                    return _vm.getStockTypeHistory()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }